<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->
<template>
  <v-container fluid>
    <v-fade-transition>
      <v-skeleton-loader
        v-if="loading || !item"
        type="card"
      />
    </v-fade-transition>
    <template v-if="item">
      <v-row class="d-print-none">
        <v-col
          v-show="backBtn === !0"
          cols="auto"
        >
          <app-btn @click="$router.go(-1)">
            {{ $t('back') }}
          </app-btn>
        </v-col>
        <v-col>
          <app-btn @click="printPage">
            {{ $t('print') }}
          </app-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <h3>{{ $t('specificationPage.cat') }}: {{ item.brand_id_to_string }}</h3>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <h3>{{ $t('specificationPage.car_name') }}: {{ item.brand_item_id_to_string }}</h3>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <h3>{{ $t('attributes.model') }}: {{ item.model }}</h3>
        </v-col>
        <v-col
          v-if="item.item_specifications"
          cols="12"
        >
          <h3>{{ $t('attributes.item_specifications') }}</h3>
          <p>{{ item.item_specifications }}</p>
        </v-col>
      </v-row>

      <v-row
        v-if="images.length > 0 && !showImages"
      >
        <!--<v-col cols="12">-->
        <!--  <v-divider />-->
        <!--</v-col>-->
        <v-col cols="12">
          <v-toolbar color="primary">
            <v-toolbar-title>{{ parseAttribute('images') }}</v-toolbar-title>
          </v-toolbar>
        </v-col>
        <v-col cols="12">
          <v-carousel :height="AppIsSmall ? 480 : undefined">
            <v-carousel-item
              v-for="(image, i) in images"
              :key="i"
            >
              <v-img
                :src="image.url"
                class="page-inside-avoid ma-auto"
                contain
                content-class="page-inside-avoid"
                max-height="100%"
                max-width="100%"
              />
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>

      <v-row
        v-show="images.length > 0 && showImages"
      >
        <!--<v-col cols="12">-->
        <!--  <v-divider />-->
        <!--</v-col>-->
        <v-col cols="12">
          <v-toolbar color="primary">
            <v-toolbar-title>{{ parseAttribute('images') }}</v-toolbar-title>
          </v-toolbar>
          <!--<h2 class="primary&#45;&#45;text">-->
          <!--  {{ parseAttribute('images') }}-->
          <!--</h2>-->
        </v-col>
        <div
          class="text-center"
          style="width: 100%"
        >
          <template v-for="(image,i) in images">
            <div
              :key="i"
              class="d-inline pa-1 text-center"
              style="width: 100%"
            >
              <a
                :href="image.url"
                target="_blank"
              >
                <v-img
                  :src="image.url"
                  class="page-inside-avoid d-inline-block"
                  contain
                  content-class="page-inside-avoid"
                  max-height="100%"
                  max-width="100%"
                  width="30.33%"
                />
              </a>
            </div>
            <!--<v-col-->
            <!--  :key="i"-->
            <!--  cols="auto"-->
            <!--&gt;-->
            <!--  <v-card-->
            <!--    :href="image.url"-->
            <!--    target="_blank"-->
            <!--    width="350"-->
            <!--    class="page-inside-avoid"-->
            <!--  >-->
            <!--    <v-img-->
            <!--      class="page-inside-avoid"-->
            <!--      content-class="page-inside-avoid"-->
            <!--      :src="image.url"-->
            <!--      contain-->
            <!--      max-height="100%"-->
            <!--      max-width="100%"-->
            <!--    />-->
            <!--  </v-card>-->
            <!--</v-col>-->
          </template>
        </div>
      </v-row>
      <v-row
        v-if="videos.length > 0"
        class="d-print-none"
      >
        <!--<v-col cols="12">-->
        <!--  <v-divider />-->
        <!--</v-col>-->
        <v-col cols="12">
          <v-toolbar color="primary">
            <v-toolbar-title>{{ parseAttribute('videos') }}</v-toolbar-title>
          </v-toolbar>
          <!--<h2 class="primary&#45;&#45;text">-->
          <!--  {{ parseAttribute('videos') }}-->
          <!--</h2>-->
        </v-col>
        <template v-for="(video,i) in videos">
          <v-col
            :key="i"
            cols="12"
            sm="4"
          >
            <iframe
              v-if="video.embed"
              width="100%"
              height="315"
              :src="video.url"
              :title="item.brand_item_id_to_string"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />
            <video
              v-else
              controls
              height="315"
              width="100%"
            >
              <source
                :src="video.url"
                :type="video.mime_type"
              >
              Your browser does not support the video tag.
            </video>
          </v-col>
        </template>
      </v-row>
      <v-row
        v-if="files.length > 0"
        align="center"
        class="d-print-none"
        justify="center"
        justify-sm="start"
      >
        <!--<v-col cols="12">-->
        <!--  <v-divider />-->
        <!--</v-col>-->
        <v-col cols="12">
          <v-toolbar color="primary">
            <v-toolbar-title>{{ parseAttribute('files_show') }}</v-toolbar-title>
          </v-toolbar>
          <!--<h2 class="primary&#45;&#45;text">-->
          <!--  {{ parseAttribute('files_show') }}-->
          <!--</h2>-->
        </v-col>
        <template v-for="(file,i) in files">
          <v-col
            :key="i"
            align-self="center"
            cols="auto"
            sm="auto"
          >
            <app-btn
              :href="file.url"
              target="_blank"
              class="mb-2"
            >
              {{ $t('download') }}
            </app-btn>
            <iframe
              :src="`${file.url}#toolbar=1`"
              height="300px"
              width="100%"
              style="border: 0"
            />
            <v-card
              v-if="false"
              :href="file.url"
              class="text-center py-2"
              min-height="150"
              target="_blank"
              width="150"
            >
              <v-icon size="80">
                picture_as_pdf
              </v-icon>
              <br>
              <br>
              <p class="text-truncate">
                {{ file.description }}
              </p>
              <v-icon size="25">
                download
              </v-icon>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </template>
  </v-container>
</template>
<script>
import { MetaInfoMixin } from '@mixins'

export default {
  name: 'ViewSpecification',
  mixins: [MetaInfoMixin],
  props: {
    backBtn: {
      type: Boolean,
      default: () => !1
    },
    model: {
      type: Object,
      default: () => null
    },
    id: {
      type: [String, Number],
      default: () => null
    }
  },
  data: () => ({
    item: null,
    loading: !0,
    showImages: !1
  }),
  computed: {
    images () {
      return this.item?.attachments?.filter(e => e.type === 'image') || []
    },
    videos () {
      let v = this.item?.attachments?.filter(e => e.type === 'video') || []
      if (this.item?.embed_youtube) {
        v = [
          {
            type: 'video',
            url: this.item.embed_youtube,
            embed: !0
          },
          ...v
        ]
      }
      return v
    },
    files () {
      // return this.item?.attachments?.filter(e => ['image', 'video'].indexOf(e.type) === -1) || []
      return this.item?.attachments?.filter(e => e.type === 'pdf') || []
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      if (this.$route.params.item) {
        this.item = this.$route.params.item
        this.loading = !1
        return
      }
      if (this.model) {
        this.item = this.model
        this.loading = !1
        return
      }
      const { id } = this || {}
      if (id) {
        this.loading = !0
        try {
          const { _data: item } = await this.apiService.specification.publicShow(id)
          this.item = item || null
        } catch (e) {
          const { _message } = e || {}
          if (_message) {
            this.alertError(_message)
          } else if (e?.message) {
            this.alertError(e?.message)
          }
        } finally {
          this.loading = !1
        }
      }
    },
    printPage () {
      if (!this.showImages) {
        this.showImages = !0
      }
      this.$nextTick(() => {
        setTimeout(() => {
          print()
          if (this.showImages) {
            this.showImages = !1
          }
        }, 90)
      })
    }
  }
}
</script>
